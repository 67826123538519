.wrapper {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  width: 100%;
  height: 250px;
}

.card {
  height: 250px;
  width: 100%;
  position: relative;
  display: flex;
}

.card:hover:before {
  opacity: 1;
}

.card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}
.card img {
  width: 100%;
  height: 100%; /* Make sure the image fills the entire container */
  object-fit: cover; /* Ensure the image covers the entire container while maintaining aspect ratio */
  position: absolute;
  top: 0;
  left: 0;
  background-color: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1));
}


.location-info {
  position: absolute;
  bottom: -198px;
  left: 20px;
  z-index: 3;
  color: black;
  transition: opacity 0.5s; /* Add transition for smooth animation */
}

.card:hover .location-info {
  opacity: 0; /* Hide location info when card is hovered */
}

.card .info {
  position: relative;
  z-index: 3;
  color: black;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s;
}

.card:hover .info {
  opacity: 1;
  transform: translateY(0px);

}

.card .info button {
  padding: 0.6rem;
  outline: none;
  border: none;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s ease;
  position: absolute;
  bottom: -250px;
}

.search-icon { 
  bottom : -97px;
  height: 30px;
  width: 30px;
  left: 50%;
  background-color: white;
}


.okcancelbutton {
  margin-top:  -200px !important;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

@media screen and (max-width: 768px) {
  .wrapper {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    width: 100%;
  }
  .card img {
    width: 100%;
    height: 100%; /* Make sure the image fills the entire container */
    object-fit: cover; /* Ensure the image covers the entire container while maintaining aspect ratio */
    position: absolute;
    top: 0;
    left: 0;
    background-color: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1));
  }
  .card {
    width: 100%;
    position: relative;
    display: flex;
  }

  
.card .info button {
  padding: 0.6rem;
  outline: none;
  border: none;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s ease;
  position: absolute;
  
}

.search-icon { 
  height: 30px;
  width: 30px;
  left:50%;
  background-color: white;
}
 
}

@media screen and (max-width: 425px ) {
  .wrapper {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    width: 100%;
  }
  .card img {
    width: 100%;
    height: 100%; /* Make sure the image fills the entire container */
    object-fit: cover; /* Ensure the image covers the entire container while maintaining aspect ratio */
    position: absolute;
    top: 0;
    left: 0;
    background-color: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1));
  }
  .card {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
  }
  .card .info button {
    padding: 0.6rem;
    outline: none;
    border: none;
    color: black;
    font-weight: bold;
    cursor: pointer;
    transition: 0.4s ease;
  }
  .search-icon { 
    height: 30px;
    width: 30px;
    left:45%;
    background-color: white;
  }
}



