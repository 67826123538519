/* header - style */

.header {
  position: sticky;
  padding: 0%;
  top: 0%;
  background-color: #ffffff;
  z-index: 99;
  height: 70px;
}

.header-container {
  display: flex;
  transition: width 0.3s ease;
  justify-content: space-between;
  padding: 0px 14px;
  margin: 0px 0px;
}

.header-title {
  font-size: 24px;
  color: #7f7f7f;
  font-weight: 700;
}

.header-icon {
  font-size: 30px;
  color: #7f7f7f;
}

.header-crossicon {
  font-size: 30px;
  margin-right: 850px;
  color: #7f7f7f;
}

/* sidebar - style */

.desktop-screen-sidebar {
  background-color: #001529 !important;
  position: sticky !important;
  height: 100vh !important;
  overflow-y: auto !important;
  top: 0% !important;
  left: 0% !important;
  bottom: 0% !important;
}

.mobile-screen-sidebar {
  background-color: #001529 !important;
  position: fixed !important;
  height: 100vh !important;
  overflow-y: auto !important;
  top: 0% !important;
  left: 0% !important;
  bottom: 0% !important;
  z-index: 1000 !important;
}

.sidebar-menu {
  font-family: 'Open Sans';
  border: #001529;
  background-color: #001529 !important;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 0.7;
  color: #fff !important;
}

.sidebar-menu-active {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: black !important;
  background-color:  #fff !important;
}
.ant-menu-submenu-active {
  background-color: #001529 !important;
}
.ant-layout-sider-trigger {
  background-color: #001529 !important;
  border-top: 1px solid #001529 !important;
  color:  #fff;
}

::-webkit-scrollbar {
  width: 0;
}

.ant-drawer-body {
  background-color: #001529 !important;
}

:where(.css-dev-only-do-not-override-17sses9).ant-drawer .ant-drawer-content {
  width: 70%;
}

/* Create a new CSS file or add to your existing one */
/* Add these styles to achieve a similar look to the example */

/* Sidebar styles */
.desktop-screen-sidebar {
  background-color: #001529;
  color: #fff;
}

.sidebar-menu,
.sidebar-menu-active {
  transition: background-color 0.3s, color 0.3s;
}

/* Hover effect */
.sidebar-menu:hover,

.sidebar-menu-active:hover {
  background-color: #001529;
}
.ant-menu-submenu {
  background-color: #001529 !important;
}
.sidebar-logo {
  max-height: 50px;
}

.ant-menu-submenu-title {
  color: #fff !important;
}

.ant-menu-submenu-title:hover {
  color: #fff;
}

.ant-menu-item {
  padding-left: 5% !important;
}

.ant-menu-submenu-title {
  padding-left: 5% !important;
}

.ant-menu.ant-menu-sub.ant-menu-inline {
  background-color: transparent !important;
}

.ant-menu-submenu-title {
  white-space: nowrap !important;
}

.ant-menu-submenu-arrow {
  color: #fff !important;
}


.text-orange-500 {
  color: #f97316 !important;
}


.editMenuIcon {
  font-size: 20px;
  color: #f97316;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

:where(.css-dev-only-do-not-override-17sses9).ant-menu-inline .ant-menu-item {
  line-height: 21px !important;
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
}

