/* Override styles for TimePicker */
.custom-time-picker .ant-time-picker-footer {
    background-color: orange; /* Change background color of footer */
  }
  
  .custom-time-picker .ant-btn-primary {
    background-color: orange; /* Change background color of primary buttons (e.g., OK button) */
    border-color: orange; /* Change border color of primary buttons */
  }
  
  .custom-time-picker .ant-btn-primary:hover,
  .custom-time-picker .ant-btn-primary:focus {
    background-color: #ff9900; /* Adjust hover/focus background color */
    border-color: #ff9900; /* Adjust hover/focus border color */
  }
  