
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');


* {
    /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
    font-family: 'Open Sans',  sans-serif;
    font-size:  98%;
}
:where(.css-dev-only-do-not-override-xu9wm8).ant-modal .ant-modal-content  {
    margin-top:  -78px;
}

.btn-brand {
    background-color: #f97316 !important;
    padding:  10px 15px;
    color: white;
    border-radius: 5px;
}



.text-orange-500 {
    color: #f97316 !important;
  }

  
.bg-orange-500 {
    background-color: #f97316 !important;
  }


.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f97316 !important;
    border-color: #f97316 !important;
}




/* customStyles.css */
.ant-select-focused .ant-select-selector {
    /* Remove default focus border */
    outline: none !important;
    box-shadow: none !important;
    border-color: #d9d9d9 !important; /* Or your desired border color */
  }
  
  /* Additional styles to ensure consistent appearance */
  .ant-select-selection-search input {
    outline: none !important;
    box-shadow: none !important;
  }
  
  .ant-select-multiple .ant-select-selection-search input {
    outline: none !important;
    box-shadow: none !important;
    margin-top: 0 !important; /* Reset margin-top */

  
  }
  
  .ant-select-selection-item {
    outline: none !important;
    box-shadow: none !important;
  }
  
  /* Optional: Customize border color on focus */
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-selector-focused {
    border-color: #40a9ff !important; /* Your custom border color */
  }
  
  .ant-select {
   height: 40px !important;
  }
  
  .ant-select-selector {
    height: 40px !important;
  }
  
  
  /* Ensure the Select component's container is properly styled */
  .ant-select-multiple .ant-select-selector {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 4px 8px;
    overflow: hidden;
    overflow-y: auto; /* Enable vertical scrolling */
  }
  

  .ant-select-multiple .ant-select-selection-item {
    margin: 2px;
    padding: 0 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    line-height: 1.5;
    display: inline-flex;
    align-items: center;
    max-width: 100%; 
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .ant-select-multiple .ant-select-selection-search {
    margin-left: 2px;
    flex-grow: 1;
  }
  
  .ant-select-multiple .ant-select-selection-search input {
    outline: none !important;
    box-shadow: none !important;
    padding: 4px 0;
    line-height: 1.5 !important;
    width: auto !important;
  }
  
  .ant-select-dropdown {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .ant-select-multiple .ant-select-clear {
    right: 8px;
  }
  
  .custom-pagination .ant-pagination-item-active {
    background-color: orange;
    border-color: orange;
    color: white;
  }
  
  .custom-pagination .ant-pagination-item-active a {
    color: white;
    background-color: #f97316;
  }
  
  :where(.css-dev-only-do-not-override-17sses9).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #f97316 !important;
    background-color: #f97316 !important;
  }