.tree {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.tree-node-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tree-node {
  text-align: center;
  position: relative;
}

.node-card {
  color: white;
  border: none;
  padding: 20px;
  margin-top:  15px !important;
}

.node-label {
  padding: 8px;
  background-color: #f97316;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: inline-block;
  max-width: 400px;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  cursor: pointer;
  font-size: 15px;
}

.children {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.left-child, .right-child {
  width: 50%;
}

.empty-placeholder {
  width: 50%;
  height: 60px; /* Adjust as needed to match node dimensions */
}


.connector {
  position: relative;
  height: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.vertical-line {
  width: 2px;
  height: 35px;
  background-color: #f97316;
}

.single-vertical-line {
  height: 40px;
  background-color: #f97316;
}

.horizontal-line-container {
  display: flex;
  justify-content: center;
  width: 100%;
  top: 35px;
  position: absolute;
}

.horizontal-line {
  width: 50%;
  height: 2px;
  background-color: #f97316;
}

.custom-table {
  background-color: #f97316;
}

.custom-table table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table th,
.custom-table td {
  border: 1px solid #ffffff;
  padding: 8px;
  text-align: center;
  color: white;
}

.custom-table th {
  background-color: #f97316;
  color: white;
  font-weight: bold;
  font-size: medium;
  text-align: center;
}

.left-vertical-line {
  width: 2px;
    height: 35px;
    background-color: #f97316;
    margin-right: 48%;
    float: right;
}