.antInputClass {
    border-color: rgb(156 163 175) !important;
    height: 60px;
    font-size: 18px;
    border-radius: 10px;
    width: 100%;
    padding: 0 10px;
    color: black;
}

.antInputClass select {
    border-color: rgb(156 163 175) !important;
    height: 60px;
    font-size: 18px;
    border-radius: 10px;
    width: 100%;
    padding: 0 10px;
    color: black;
}