/* Dashboard.css */
.marquee-container {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    border: 1px solid #ccc;
    background-color: #f7fafc;
    border-radius: 0.375rem;
    padding: 1rem;
    margin: 1.25rem auto;
  }
  
  .marquee {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 25s linear infinite;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  